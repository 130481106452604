// hooks/useDynamicBottom.js
import { useState, useEffect } from 'react';

const useDynamicBottom = (offset = 10, sideMargin = '1rem') => {
  const [style, setStyle] = useState({
    bottom: '2rem',
    marginLeft: sideMargin,
    marginRight: sideMargin
  });

  const updateStyle = () => {
    const footer = document.querySelector('footer');
    if (footer) {
      const footerRect = footer.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const footerTop = footerRect.top;

      let newBottomStyle = '2rem'; // Default bottom style
      if (footerTop <= windowHeight) {
        newBottomStyle = `${windowHeight - footerTop + offset}px`;
      }

      setStyle({
        bottom: newBottomStyle,
        marginLeft: sideMargin,
        marginRight: sideMargin
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updateStyle);
    window.addEventListener('resize', updateStyle); // Ensure style updates on window resize
    updateStyle(); // Initial check on mount

    return () => {
      window.removeEventListener('scroll', updateStyle);
      window.removeEventListener('resize', updateStyle);
    };
  }, [offset, sideMargin]);

  return style;
};

export default useDynamicBottom;
